import { Component, OnInit } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html'
})

export class HeaderComponent implements OnInit {
  // the locales the app supports
  locales = [
    { label: 'Français', value: 'fr', url: 'assets/icons/logo-french.png' },
    { label: 'English', value: 'en-US', url: 'assets/icons/logo-english.png' }
  ];
  // the user's locale
  detectedLocale = '';
  // the default locale
  locale = this.locales[0].value;

  constructor(private translocoService: TranslocoService ) {
    this.detectedLocale = this.getUsersLocale('en-US');

  }

  toggleMenu() {
    const OPEN_HEADER_NAV_CLASS = 'page-header__nav--open'
    const OPEN_BTN_BURGER_MENU_CLASS = 'page-header__burger--open'
    const btnBurgerMenu = document.querySelector('.page-header__burger')
    const headerNav = document.querySelector('.page-header__nav')
    if (headerNav.classList.contains(OPEN_HEADER_NAV_CLASS)) {
      document.body.style.overflow = ''
      btnBurgerMenu.classList.remove(OPEN_BTN_BURGER_MENU_CLASS)
      headerNav.classList.remove(OPEN_HEADER_NAV_CLASS);
    } else {
      document.body.style.overflow = 'hidden'
      btnBurgerMenu.classList.add(OPEN_BTN_BURGER_MENU_CLASS)
      headerNav.classList.add(OPEN_HEADER_NAV_CLASS);
    }
  }
  ngOnInit(): void {
    /*let mainNavLinks = document.querySelectorAll('.page-header__nav .page-header__btn');

    mainNavLinks.forEach(link => {
      link.addEventListener("click", event => {
        event.preventDefault();
        let target = document.getElementById(link.getAttribute('data-href')).querySelector('.section-anchor') as HTMLElement;
        const headerHeight = document.querySelector('header').offsetHeight;
        let targetOffset = target.offsetTop - headerHeight
        window.scrollTo({top: targetOffset, behavior: 'smooth'});
      });
    });

    Array.from(mainNavLinks).forEach(nav => {
      nav.addEventListener('click', () => {
        document.body.style.overflow = '';
        nav.closest('.page-header__nav').classList.remove('page-header__nav--open');
        document.querySelector('.page-header__burger').classList.remove('page-header__burger--open');
      });
    });*/
  }

  getUsersLocale(defaultValue: string): string {
    if (typeof window === 'undefined' || typeof window.navigator === 'undefined') {
      return defaultValue;
    }
    const wn = window.navigator as any;
    let lang = wn.languages ? wn.languages[0] : defaultValue;
    lang = lang || wn.language || wn.browserLanguage || wn.userLanguage;
    return lang;
  }
  // change locale/language at runtime
  updateLocale(locale) {
    console.log(locale)
    if (this.locales.some(l => l.value === locale)) {
      this.locale = locale;
    }
    const lang = locale.substring(0, 2);
    this.translocoService.setActiveLang(lang);
  }
}
