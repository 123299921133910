<div class="rbt-container">
  <div class="homeBanner rbt-container__wrapper" style="background-image:'url(assets/images/home/home-banner.png)';">
    <span class="homeBanner__title">
      {{'HOME.BANNER_TITLE' | transloco}}
    </span>

    <span class="homeBanner__subTitle">
      {{'HOME.BANNER_SUBTITLE' | transloco}}
    </span>

    <a class="homeBanner__btn rbt-btn -gold" routerLink="/contact">
      {{'HOME.BANNER_CTA' | transloco}}
    </a>
  </div>
</div>

<div class="rbtBenefit">
  <div class="rbtBenefit__header">
    {{'HOME.ADVANTAGE_TITLE' | transloco}}
  </div>
  <div class="rbtBenefit__items">
    <div class="rbtBenefit__item">
      <span class="rbtBenefit__itemImg">
        <img src="assets/icons/icon-rapidity.png" alt="rapidité" />
      </span>
      <span class="rbtBenefit__itemTitle"> {{'HOME.RAPIDITY_TITLE' | transloco}}</span>
      <span class="rbtBenefit__itemDescription">{{'HOME.RAPIDITY_CONTENT' | transloco}} </span>
    </div>
    <div class="rbtBenefit__item">
      <span class="rbtBenefit__itemImg">
        <img src="assets/icons/icon-security.png" alt="sécurité" />
      </span>
      <span class="rbtBenefit__itemTitle"> {{'HOME.CONFORT_TITLE' | transloco}}</span>
      <span class="rbtBenefit__itemDescription"> {{'HOME.CONFORT_CONTENT' | transloco}}</span>
    </div>

    <div class="rbtBenefit__item">
      <span class="rbtBenefit__itemImg">
        <img src="assets/icons/icon-precision.png" alt="précision" />
      </span>
      <span class="rbtBenefit__itemTitle">{{'HOME.PRECISION_TITLE' | transloco}}</span>
      <span class="rbtBenefit__itemDescription">{{'HOME.PRECISION_CONTENT' | transloco}}</span>
    </div>

    <div class="rbtBenefit__item">
      <span class="rbtBenefit__itemImg">
        <img src="assets/icons/icon-mobility.png" alt="mobilité" />
      </span>
      <span class="rbtBenefit__itemTitle">{{'HOME.MOBILITY_TITLE' | transloco}}</span>
      <span class="rbtBenefit__itemDescription">{{'HOME.MOBILITY_CONTENT' | transloco}}</span>
    </div>

  </div>

  <!--<div class="rbtBenefit__btn">
    <a class="rbt-btn rbt-btn--gold -empty" href="">{{'HOME.SEE_ALL' | transloco}}</a>
  </div>-->
</div>

<div class="rbtNews -full">
  <div class="rbtNews__wrapper -full">
    <div class="rbtNews__media">
      <img src="assets/images/home/pose-carlita.jpg" alt="pose carlita" />
    </div>
    <div class="rbtNews__contentWrapper">
      <div class="rbtNews__content">
        <p class="rbtNews__contentTitle">{{'HOME.CARLITA_TITLE' | transloco}}</p>
        <p class="rbtNews__contentDescription">
          {{'HOME.CARLITA_CONTENT' | transloco}}
        </p>
        <div class="rbtNews__contentBtn">
          <a class="rbt-btn rbt-btn--gold -empty" routerLink="/carlita"> {{'HOME.CARLITA_CTA' | transloco}}</a>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="rbtNews">
  <div class="rbtNews__wrapper -auto">
    <div class="rbtNews__left -content">
      <div class="rbtNews__content">
        <p class="rbtNews__contentTitle">{{'HOME.TC_TITLE' | transloco}}</p>
        <p class="rbtNews__contentDescription">
          {{'HOME.TC_CONTENT' | transloco}}
        </p>
        <div class="rbtNews__contentBtn">
          <a class="rbt-btn rbt-btn--gold -empty" routerLink="/gluepuller">{{'HOME.TC_CTA' | transloco}}</a>
        </div>
      </div>

    </div>
    <div class="rbtNews__right -media">
      <img src="assets/images/home/tce-600.jpg" alt="tce 600" />
    </div>
  </div>
</div>