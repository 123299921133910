<footer class="footer">
  <div class="footer__top">
    <span class="footer__topTitle">
      Partenaires
    </span>
    <div class="align-items-center">
      <ul class="footer__icons">
        <li class="footer__icon">
          <img src="assets/icons/logo-oppbtp.png" alt="logo opp btp" />
        </li>
        <li class="footer__icon">
          <img src="assets/icons/logo-capeb.png" alt="logo capeb" />
        </li>
        <li class="footer__icon">
          <img src="assets/icons/logo-iris-st.png" alt="logo iris" />
        </li>

        <li class="footer__icon">
          <img src="assets/icons/logo-sp-impulse-labs.png" alt="logo sp impulse lab" />
        </li>

        
      </ul>
    </div>

    <span class="footer__topTitle">
      "Ils en parlent"
    </span>
    <div class="align-items-center">
      <ul class="footer__icons">
        <li class="footer__icon">
          <img src="assets/icons/logo-europe1.png" alt="logo europe 1" />
        </li>
        <li class="footer__icon">
          <img src="assets/icons/logo-m6.png" alt="logo M6" />
        </li>
        <li class="footer__icon">
          <img src="assets/icons/batiradio-logo.png" alt="batiradio logo" />
        </li>
        <li class="footer__icon">
          <img src="assets/icons/logo-moniteur.jpg" alt="logo moniteur" />
        </li>
        <li class="footer__icon">
          <img src="assets/icons/bar-de-leco.png" alt="bar de leco" />
        </li>
      </ul>
    </div>
    <p class="footer__topTxt">Une innovation Made in France au service des carreleurs.</p>
  </div>
  <div class="footer__bottom">
    <div class="row">
      <!--<div class="col-md-auto">
        <a class="footer__bottomLink" href="#">
          Qui somme-nous ?
        </a>
      </div>-->

      <div class="col-md-auto">
        <a class="footer__bottomLink" href="#">
        Nos produits
        </a>
      </div>

      <div class="col-md-auto">
        <p>Bureaux: 5 rue Sainte Barbe - 91590 La Ferté Alais</p>
        <a href="mailto:serge.semete@robotile.fr" class="footer__bottomLink">serge.semete@robotile.fr </a> - 
        <a href="tel:0685943424" class="footer__bottomLink">0685943424</a>
      </div>
    </div>  
    
  </div>
</footer>