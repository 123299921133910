
import { Injectable } from '@angular/core';
import { TranslocoLoader, TRANSLOCO_LOADER } from '@ngneat/transloco';
import { of } from 'rxjs';
import en from './assets/i18n/en.json';
import fr from './assets/i18n/fr.json';



/**
 * DO NOT USE THIS IS LOADER IN YOUR APP
 * 
 * This is a special loader for stackblitz.
 * In a normal app, use the loader generated by the transloco schematic.
 */
@Injectable({ providedIn: 'root' })
export class HttpLoader implements TranslocoLoader {
  getTranslation(langPath: string) {
    if (langPath === 'en') {
      return of(en);
    } else {
      return of(fr);
    }
  }
}

export const translocoLoader = { provide: TRANSLOCO_LOADER, useClass: HttpLoader };
