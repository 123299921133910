<div class="rbt-container">
  <div class="homeBanner rbt-container__wrapper" style="background-image:'url(assets/images/carlita/carlita-sante-securite.jpg)';">
    <span class="homeBanner__title">
      {{'CARLITA.BANNER_TITLE' | transloco}}
    </span>

    <span class="homeBanner__subTitle">
      {{'CARLITA.BANNER_SUBTITLE' | transloco}}
    </span>

    <button class="homeBanner__btn rbt-btn -gold" routerLink="/contact">
      {{'CARLITA.ASK_DEMO' | transloco}}
    </button>
  </div>
</div>

<div class="rbtBenefit">
  <div class="rbtBenefit__items">
    <div class="rbtBenefit__item">
      <div class="rbtBenefit__itemWrapper">
        <span class="rbtBenefit__itemImg">
          <img src="assets/icons/icon-security.png" alt="sécurité" />
        </span>
        <span class="rbtBenefit__itemTitle">{{'CARLITA.CONFORT_TITLE' | transloco}}</span>
        <span class="rbtBenefit__itemDescription">{{'CARLITA.CONFORT_DESC' | transloco}}</span>
      </div>  
    </div>
    

    <div class="rbtBenefit__item">
      <div class="rbtBenefit__itemWrapper">
        <span class="rbtBenefit__itemImg">
          <img src="assets/icons/icon-precision.png" alt="précision" />
        </span>
        <span class="rbtBenefit__itemTitle">{{'CARLITA.PRECISION_TITLE' | transloco}}</span>
        <span class="rbtBenefit__itemDescription">{{'CARLITA.PRECISION_DESC' | transloco}}</span>
      </div>  
    </div>

    <div class="rbtBenefit__item">
      <div class="rbtBenefit__itemWrapper">
        <span class="rbtBenefit__itemImg">
          <img src="assets/icons/icon-rapidity.png" alt="rapidité" />
        </span>
        <span class="rbtBenefit__itemTitle">{{'CARLITA.RAPIDITE_TITLE' | transloco}}</span>
        <span class="rbtBenefit__itemDescription">{{'CARLITA.RAPIDITE_DESC' | transloco}}</span>
      </div>
    </div>

    <div class="rbtBenefit__item">
      <div class="rbtBenefit__itemWrapper">
        <span class="rbtBenefit__itemImg">
          <img src="assets/icons/icon-mobility.png" alt="mobilité" />
        </span>
        <span class="rbtBenefit__itemTitle">{{'CARLITA.MOBILITE_TITLE' | transloco}}</span>
        <span class="rbtBenefit__itemDescription">{{'CARLITA.MOBILITE_DESC' | transloco}}</span>
      </div>
    </div>

  </div>
</div>

<div class="rbt-title">
  <h3 class="h3-title blue-color">
    {{'CARLITA.DEBOUT_TITLE' | transloco}}
  </h3>
</div>

<div class="row rbt-container">
  <div class="col-md-6">
    <figure class="rbt-figure">
      <img src="assets/images/carlita/pose-alone.jpg" alt="pose sans carlita" class="rbt-image" />
      <figcaption>{{'CARLITA.DEBOUT_SANS' | transloco}}</figcaption>
    </figure>  
  </div>
  <div class="col-md-6">
    <figure class="rbt-figure">
      <img src="assets/images/carlita/pose-with-carlita.png" alt="pose avec carlita" class="rbt-image" />
      <figcaption class="right">{{'CARLITA.DEBOUT_AVEC' | transloco}}</figcaption>
    </figure>  
</div>
</div>

<div class="rbtNews -full rbt-container">
  <div class="rbtNews__wrapper -full">
    <div class="rbtNews__contentWrapper -small -noBackground">
      <div class="rbtNews__content">
        <p class="rbtNews__contentTitle -blue">{{'CARLITA.DOUBLE_ENCOLLAGE_TITLE' | transloco}}</p>
        <div class="rbtNews__contentDescription">
          <p>{{'CARLITA.DOUBLE_ENCOLLAGE_LIGNE1' | transloco}}<strong>{{'CARLITA.DOUBLE_ENCOLLAGE_LIGNE1STRONG' | transloco}}</strong></p>
          <p>{{'CARLITA.DOUBLE_ENCOLLAGE_LIGNE2' | transloco}} </p>
        </div>
      </div>
    </div>

    <div class="rbtNews__media -big">
      <img src="assets/images/carlita/double-encollage.jpg" alt="double encollage" />
    </div>
  </div>
</div>

<div class="rbtNews">
  <div class="rbtNews__wrapper -auto">
    <div class="rbtNews__left -media">
      <img src="assets/images/carlita/vue-eclate.png" alt="carlita vue eclate" />
    </div>
    <div class="rbtNews__right -content -noBackground">
      <div class="rbtNews__content">
        <p class="rbtNews__contentTitle">
          <a href="{{'CARLITA.TECHNICAL_SHEET_PDF' | transloco}}"target="_blank" 
          type="application/octet-stream" download="{{'CARLITA.TECHNICAL_SHEET_PDF' | transloco}}">{{'CARLITA.FICHE_TECH' | transloco}}</a>
        </p>
        <p class="rbtNews__contentDescription">
          {{'CARLITA.FICHE_DESC' | transloco}}
        </p>
        <!--<div class="rbtNews__contentBtn">
          <a class="rbt-btn rbt-btn--gold -empty" href="">
            <img src="assets/images/carlita/vue-eclate.jpg" alt="carlita vue eclate" />
          </a>
        </div>-->
      </div>
    </div>
  </div>
</div>

<div class="rbtNews -half -column rbt-container">
  <div class="rbtNews__media -full">
    <video loop muted playsinline controls class="w100">
      <source src="assets/videos/carlita-pose.mp4" type="video/mp4" /> Ici l'alternative à la vidéo : un lien de
        téléchargement, un message, etc. 
    </video>
  </div>
  <div class="rbtNews__content">
    <h3 class="h3-title orange-color">{{'CARLITA.SOLUTION_TITLE' | transloco}}</h3>
    <div class="rbtNews__contentDescription">
      <p>{{'CARLITA.SOLUTION_DESC1' | transloco}} <br/>
        {{'CARLITA.SOLUTION_DESC2' | transloco}}<br/>
      </p>    
    </div>
    
  </div>
</div>

