<div class="rbt-container">
  <div class="homeBanner rbt-container__wrapper" style="background-image:'url(assets/images/gluepuller/glue-puller-banner.jpg)';">
    <span class="homeBanner__title">
      {{'GLUEPULLER.BANNER_TITLE' | transloco}}
    </span>

    <span class="homeBanner__subTitle">
      {{'GLUEPULLER.BANNER_SUBTITLE' | transloco}}
    </span>
  </div>
</div>

<div class="rbtBenefit">
  <div class="rbtBenefit__items">
    <div class="rbtBenefit__item">
      <div class="rbtBenefit__itemWrapper">
        <span class="rbtBenefit__itemImg">
          <img src="assets/icons/icon-security.png" alt="sécurité" />
        </span>
        <span class="rbtBenefit__itemTitle">{{'GLUEPULLER.CONFORT_TITLE' | transloco}}</span>
        <span class="rbtBenefit__itemDescription">{{'GLUEPULLER.CONFORT_DESC' | transloco}}</span>
      </div>  
    </div>

    <div class="rbtBenefit__item">
      <div class="rbtBenefit__itemWrapper">
        <span class="rbtBenefit__itemImg">
          <img src="assets/icons/icon-precision.png" alt="précision" />
        </span>
        <span class="rbtBenefit__itemTitle">{{'GLUEPULLER.PRECISION_TITLE' | transloco}}</span>
        <span class="rbtBenefit__itemDescription">{{'GLUEPULLER.PRECISION_DESC' | transloco}}</span>
      </div>  
    </div>

    <div class="rbtBenefit__item">
      <div class="rbtBenefit__itemWrapper">
        <span class="rbtBenefit__itemImg">
          <img src="assets/icons/icon-rapidity.png" alt="rapidité" />
        </span>
        <span class="rbtBenefit__itemTitle">{{'GLUEPULLER.RAPIDITE_TITLE' | transloco}}</span>
        <span class="rbtBenefit__itemDescription">{{'GLUEPULLER.RAPIDITE_DESC' | transloco}}</span>
      </div>
    </div>

    <div class="rbtBenefit__item">
      <div class="rbtBenefit__itemWrapper">
        <span class="rbtBenefit__itemImg">
          <img src="assets/icons/icon-simplicity.png" alt="simplicité" />
        </span>
        <span class="rbtBenefit__itemTitle">{{'GLUEPULLER.SIMPLICITE_TITLE' | transloco}}</span>
        <span class="rbtBenefit__itemDescription">{{'GLUEPULLER.SIMPLICITE_DESC' | transloco}}</span>
      </div>
    </div>

  </div>
</div>

<div class="rbt-title">
  <h3 class="h3-title blue-color">
  {{'GLUEPULLER.FORMAT_TITLE' | transloco}}
  </h3>
</div>

<div class="row rbt-container">
  <div class="col-md-6">
    <figure class="rbt-figure">
      <img src="assets/images/gluepuller/glue-puller-manual.jpg" alt="pose sans tire colle" class="rbt-image" />
      <figcaption>{{'GLUEPULLER.SANS' | transloco}}</figcaption>
    </figure>  
  </div>
  <div class="col-md-6 d-flex">
    <video loop muted playsinline controls class="w100">
      <source src="assets/videos/glue-puller.mp4" type="video/mp4" /> Ici l'alternative à la vidéo : un lien de
        téléchargement, un message, etc.
        
        <span class="legend">Pose sans Tire colle</span>
    </video>
  </div>
</div>

<div class="rbt-title">
  <h3 class="h3-title orange-color">
    {{'GLUEPULLER.FORMAT_SUBTITLE' | transloco}}
  </h3>
  <p>{{'GLUEPULLER.FORMAT_DESC' | transloco}}</p>
</div>

<div class="rbtNews">
  <div class="rbtNews__wrapper -auto">
    <div class="rbtNews__left -media">
      <img src="assets/images/gluepuller/tce-600.jpg" alt="TCE 600" />
    </div>
    <div class="rbtNews__right -content -noBackground">
      <div class="rbtNews__content">
        <div class="rbtNews__contentTitle">
          <a href="{{'GLUEPULLER.TECHNICAL_SHEET_PDF' | transloco}}" target="_blank" 
          type="application/octet-stream" download="{{'GLUEPULLER.TECHNICAL_SHEET_PDF' | transloco}}">
          {{'GLUEPULLER.FICHE_TECH' | transloco}}
         </a>
        </div>
        <div class="rbtNews__contentDescription">
          <p>{{'GLUEPULLER.FICHE_DESC1' | transloco}}</p>
          <p>{{'GLUEPULLER.FICHE_DESC2' | transloco}} <br/>
            {{'GLUEPULLER.FICHE_DESC3' | transloco}}<br/>
            {{'GLUEPULLER.FICHE_DESC4' | transloco}}<br/>
            {{'GLUEPULLER.FICHE_DESC5' | transloco}} <br/>
            {{'GLUEPULLER.FICHE_DESC6' | transloco}}<br/><br/> </p>
          
         <p><strong>{{'GLUEPULLER.FICHE_DESC7' | transloco}}</strong> {{'GLUEPULLER.FICHE_DESC8' | transloco}}</p>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="rbt-title">
  <h3 class="h3-title blue-color">
    {{'GLUEPULLER.FINALE' | transloco}}
  </h3>
  <p> <a class="rbt-btn -gold -auto" routerLink="/contact">{{'GLUEPULLER.COMMANDE' | transloco}}</a></p>
</div>
